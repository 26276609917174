import { useContext, useMemo, useCallback } from 'react';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';

import EnrollButton from '@/app/components/core/EnrollButton';
import { MarkdownRichText, Video } from '@/app/components/shared';
import { Paragraph, RichText } from '@swordhealth/ui-corporate';
import {
  CustomContainer,
  Wrapper,
  Text,
  SignIn,
  EligibilityClaim,
  StyledTitle,
  ImageWrapper,
  EnrollButtonWrapper,
  FixedWrapper,
} from './styles';
import theme from '@/utils/styles-variables';
import { createSignInURL } from '@/utils/onboarding-urls';
import getImgSrcWorkaround, { getImgData } from '@/utils/images';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import ClientContext from '@/contexts/client.context';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';
import Section from '@/components/ui/Section';
import { compareDates } from '@/utils/dates';
import { Logos } from '@/app/components/shared/Logos/Logos';
import Portal from '@/utils/create-portal';

const ONBOARDING_BASE_URL =
  process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL ?? 'https://onboarding.swordhealth.com/';
const BUTTON_LABEL = 'Sign in here';

const Header = ({
  content: { title, subtitle, eligibilityClaim, button, previewVideo, video, videoTitle, images },
  logo,
  referralUser,
  showSwordLogo = true,
  userName,
}) => {
  const { openModal, client } = useContext(ClientContext);
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  const [ref, inView, entry] = useInView({
    threshold: 1,
    initialInView: true,
    rootMargin: '-10px',
  });

  const shownImage = useMemo(() => {
    if (isMobile && images?.imageMobile?.data) {
      return getImgData(images.imageMobile);
    }
    return images?.image?.data ? getImgData(images.image) : null;
  }, [isMobile, images]);

  const logos = useMemo(() => {
    if (!logo?.data) return [];
    return Array.isArray(logo.data) ? logo.data.slice(0, 3) : [logo.data];
  }, [logo]);

  const signInURL = useMemo(
    () =>
      createSignInURL(ONBOARDING_BASE_URL, {
        unit: client?.product?.key === 'thrive' ? 'dpt' : client?.product?.key,
        program_type: client?.product?.param,
        hide_get_started: true,
        client_ref: client?.institutionKey ?? client?.clientKey,
      }),
    [client],
  );

  const formattedTitle = useMemo(() => {
    if (referralUser) {
      return title?.replace('USER_NAME', referralUser.inviter_first_name);
    }
    if (userName?.length) {
      return title?.replace('{{USER_NAME}}', userName);
    }
    if (!userName && title.includes('{{USER_NAME}}')) {
      const titleWithoutUsername = title
        .trim()
        .substring('{{USER_NAME}}'.length)
        .replace(/,/g, ' ')
        .trim();
      return titleWithoutUsername.charAt(0).toUpperCase() + titleWithoutUsername.slice(1);
    }
    return title;
  }, [title, referralUser, userName]);

  const hasVideo = Boolean(previewVideo && video && videoTitle);
  const shouldShowFixedButton =
    isMobile && !inView && !!client.clientKey && entry?.boundingClientRect?.top < 100;
  const shouldShowSignIn =
    !referralUser && client.isActive && !compareDates(client.launchDate) && logos;

  const handleSignInClick = useCallback(() => {
    trackButtonClicked({
      action: ACTIONS.REDIRECT_TO_SIGN_IN,
      buttonType: BUTTON_TYPES.NAVIGATION,
      buttonText: BUTTON_LABEL,
      destination: signInURL,
      buttonLocation: 'header',
    });
  }, [signInURL]);

  const handleModalOpen = useCallback(() => {
    openModal({ buttonText: button.label, buttonLocation: 'header' });
  }, [openModal, button.label]);

  const enrollButton = useMemo(
    () => (
      <EnrollButton
        id="header_button"
        isActive={client.isActive}
        buttonLocation="header"
        openModal={handleModalOpen}
        client={client}
        buttonLabel={button?.label}
        product={client?.product}
        redirectToApp={client?.redirectToApp}
        buttonApp={button}
        triage={client?.hasTriage}
        referralUser={referralUser}
      />
    ),
    [client, button, referralUser, handleModalOpen],
  );

  return (
    <Section colored={referralUser} lastColored={referralUser}>
      <CustomContainer>
        <Wrapper $video={hasVideo} $image={shownImage}>
          <Logos logos={logos} centered showSwordLogo={showSwordLogo} client={client} />

          <StyledTitle id="header_title">
            <MarkdownRichText elements={['h1', 'u', 'a', 'sup']}>{formattedTitle}</MarkdownRichText>
          </StyledTitle>

          <Text id="header_subtitle">
            {referralUser
              ? subtitle?.replace('USER_NAME', referralUser.inviter_first_name)
              : subtitle}
          </Text>

          <EnrollButtonWrapper ref={ref}>{enrollButton}</EnrollButtonWrapper>

          <Portal portalId="sticky-enroll-button-portal">
            <FixedWrapper $visible={shouldShowFixedButton}>
              <EnrollButtonWrapper>{enrollButton}</EnrollButtonWrapper>
            </FixedWrapper>
          </Portal>

          {shouldShowSignIn && (
            <SignIn id="header_sign_in">
              <RichText as={Paragraph}>
                <span id="header_sign_in_text">Already have an account?&nbsp;</span>
                <a id="header_sign_in_link" href={signInURL} onClick={handleSignInClick}>
                  {BUTTON_LABEL}
                </a>
              </RichText>
            </SignIn>
          )}

          {eligibilityClaim && !referralUser && (
            <EligibilityClaim>
              <MarkdownRichText elements={['p', 'strong', 'em', 'a', 'u']}>
                {eligibilityClaim}
              </MarkdownRichText>
            </EligibilityClaim>
          )}
        </Wrapper>

        {shownImage?.url && (
          <ImageWrapper>
            <Image
              id="hero-image"
              src={getImgSrcWorkaround(shownImage.url)}
              alt={shownImage.alternativeText || ''}
              loading="eager"
              objectFit="cover"
              fill
            />
          </ImageWrapper>
        )}
      </CustomContainer>

      {hasVideo && <Video previewVideo={previewVideo} video={video} videoTitle={videoTitle} />}
    </Section>
  );
};

export default Header;
