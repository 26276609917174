import styled, { css } from 'styled-components';
import { Container } from '@/components/ui/flexbox';

import Section, { SPACE_DESKTOP, SPACE_MOBILE, SPACE_TABLET } from '@/components/ui/Section';
import { Field } from '@/components/platform/Form/styles';

export const CustomSection = styled(Section)`
  position: relative;

  ${(props) =>
    props.$noSpacerBottom &&
    css`
      margin-bottom: 0 !important;
    `}

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin-bottom: ${SPACE_DESKTOP};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: ${SPACE_TABLET};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    margin-bottom: ${SPACE_MOBILE};
  }
`;

export const CustomContainer = styled(Container)`
  position: relative;
  z-index: 1;
  max-width: 1366px;
  display: flex;
  flex-direction: row;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column-reverse;
  }

  ${(props) =>
    props.$hasContentRight &&
    css`
      flex-direction: row-reverse;
    `}
`;

export const Content = styled.div`
  display: grid;
  width: 50%;
  min-height: 768px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 100%;
    min-height: 432px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    min-height: 180px;
  }
`;

export const Wrapper = styled.div`
  padding: 0 80px 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  z-index: 1;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 64px 0;
    min-height: auto;
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 40px 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    justify-content: center;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey.default};
  text-align: left;
  white-space: pre-line;
  margin-top: 16px;

  p {
    line-height: 20px;
  }
`;

export const FixedWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.neutral.default};
  text-align: center;
  padding: 10px 0;
  position: fixed;
  top: 0;
  z-index: 1;
  opacity: 0;
  box-shadow: rgba(233, 220, 211, 0.4) 0 2px 10px 0;

  ${(props) =>
    props.$visible &&
    css`
      opacity: 1;
    `};
`;

export const EnrollButtonWrapper = styled.div`
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    height: 46px;
    text-align: center;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;

  form {
    text-align: left;
    flex-direction: column !important;

    ${Field} {
      width: 100%;
    }
  }
`;
