import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children, portalId = 'portal-root' }) => {
  const [mounted, setMounted] = useState(false);
  const [portalContainer, setPortalContainer] = useState(null);

  useEffect(() => {
    setMounted(true);
    // First, look for existing portal container
    let element = document.getElementById(portalId);

    // If it doesn't exist, create it
    if (!element) {
      element = document.createElement('div');
      element.id = portalId;
      document.body.appendChild(element);
    }

    setPortalContainer(element);

    // Cleanup on unmount
    return () => {
      if (element && element.parentNode && element.parentNode === document.body) {
        document.body.removeChild(element);
      }
    };
  }, [portalId]);

  // Don't render anything on the server or until mounted on the client
  if (!mounted || !portalContainer) return null;

  return createPortal(children, portalContainer);
};

export default Portal;
