import styled, { css } from 'styled-components';
import { Container } from '@/components/ui/flexbox';
import { PrimaryButton } from '@/components/ui/Button';
import { Paragraph } from '@swordhealth/ui-corporate';

export const CustomContainer = styled(Container)`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column-reverse;
  }
`;

export const SignIn = styled.div`
  text-align: center;
`;

export const Wrapper = styled.div`
  padding: 80px 0;
  display: grid;
  justify-content: center;
  position: relative;
  z-index: 1;

  ${({ $video }) =>
    !$video &&
    css`
      padding-bottom: 0px !important;
    `}

  ${PrimaryButton} {
    width: max-content;
    margin: 0 auto 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 64px 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: ${(props) => (props.$video || props.$image ? `0` : `32px 0`)};
  }
`;

export const StyledTitle = styled.h1`
  max-width: 800px;
  margin: 0 auto 32px;
  text-align: center;

  font-size: 55px;
  font-family: ${({ theme }) => theme.typography.family.title};
  font-weight: 700;
  line-height: 64px;

  u {
    color: ${({ theme }) => theme.colors.primary.default};
    text-decoration: none;
  }

  sup {
    vertical-align: super;
    font-size: 50%;
    line-height: 1;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 45px;
    line-height: 56px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 16px;

    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    margin-bottom: 24px;
  }
`;

export const Text = styled(Paragraph)`
  text-align: center;
  padding-bottom: 32px;

  &:last-child {
    padding-bottom: 0;
  }

  a {
    text-decoration: underline;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    &:not(:last-child) {
      padding-bottom: 16px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    &:not(:last-child) {
      padding-bottom: 24px;
    }
  }
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey.default};
  text-align: center;
  white-space: pre-line;
  margin-top: 16px;
`;

export const EligibilityClaim = styled.span`
  margin-top: 16px;
  &,
  p {
    color: ${({ theme }) => theme.colors.grey.default};
    font-weight: 500;
    text-align: center;
    white-space: pre-line;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;
export const ImageWrapper = styled.div`
  aspect-ratio: 3/2;
  position: relative;
`;

export const FixedWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  text-align: center;
  padding: 10px 0;
  position: fixed;
  top: 0;
  z-index: 1;
  opacity: 0;
  box-shadow: rgba(233, 220, 211, 0.4) 0 2px 10px 0;

  ${(props) =>
    props.$visible &&
    css`
      opacity: 1;
    `};
`;

export const EnrollButtonWrapper = styled.div`
  margin: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 46px;
  }
`;
