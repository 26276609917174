import Image from 'next/image';
import theme from '@/utils/styles-variables';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import DynamicHero from '@/app/components/platform/DynamicHero';
import { ImageWrapper } from './styles';

const HeaderImage = ({ content, logo, showSwordLogo = true, noSpacerBottom, userName }) => {
  const { image, imageMobile } = content.image;
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_md}px)`);
  return (
    <DynamicHero
      content={content?.content}
      logo={logo}
      showSwordLogo={showSwordLogo}
      noSpacerBottom={noSpacerBottom}
      userName={userName}
    >
      <ImageWrapper>
        <Image
          id="hero-image"
          src={
            isMobile && (imageMobile?.url ?? imageMobile?.data?.attributes?.url)
              ? imageMobile?.url ?? imageMobile?.data?.attributes?.url
              : image?.url ?? image?.data?.attributes?.url
          }
          alt={
            isMobile &&
            (imageMobile?.alternativeText ?? imageMobile?.data?.attributes?.alternativeText)
              ? imageMobile?.alternativeText ?? imageMobile?.data?.attributes?.alternativeText
              : image?.alternativeText ?? image?.data?.attributes?.alternativeText
          }
          loading="eager"
          fill
        />
      </ImageWrapper>
    </DynamicHero>
  );
};

export default HeaderImage;
