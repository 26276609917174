import styled from 'styled-components';
import { Label } from '@/components/ui/Title';
import { Accordion, AccordionItem } from '@/components/core/Accordion';

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 40px;
    align-items: flex-start;
    text-align: start;
  }

  h3 {
    padding-bottom: 0;
    max-width: 640px;
  }
`;
export const HeaderText = styled(Label)`
  width: 100%;
  max-width: 640px;
  text-align: center;
  padding-top: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    text-align: left;
  }
`;

export const CustomAccordion = styled(Accordion)`
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 8px;
    border: 1px solid #e7eaf0;
  }
`;
export const CustomAccordionItem = styled(AccordionItem)`
  &:first-child {
    border-top: 1px solid transparent;
  }

  .title-wrapper {
    span {
      font-size: 18px;
      font-weight: 500;
    }

    i {
      min-height: 30px;
      min-width: 30px;
      padding: 7.5px;
      background-color: ${(props) => props.theme.colors.primary.light};
      border-radius: 50%;

      &:before,
      &:after {
        background-color: ${(props) => props.theme.colors.grey.dark};
        height: 1px;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }
`;
export const StyledContent = styled.div`
  position: relative;
`;
